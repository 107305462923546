import React from 'react';
import { useAuthDataFunctions } from 'hooks/auth/useAuthDataFunctions';
import { Link } from 'react-router-dom';
import { Menu as MenuAntd, Dropdown, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import ImgBottomHeader from 'imgs/bg-bottom-header.svg';

import { Container, Profile } from './styles';

export default function NewHeader() {
  const { data, signOut } = useAuthDataFunctions();

  const { client } = data;

  const { name, image } = client || {
    name: '',
    image: '',
  };

  let nome = name;

  let user = nome;

  const arrayNome = name
    .split(' ')
    .map(n => n.charAt(0).toUpperCase() + n.slice(1).toLowerCase());

  if (arrayNome.length > 1) {
    nome = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;

    user = `${arrayNome[0].substr(0, 1)}${arrayNome[
      arrayNome.length - 1
    ].substr(0, 1)}`;
  }

  const menu = (
    <MenuAntd>
      <MenuAntd.Item key="1" onClick={() => signOut()}>
        <span>
          <LogoutOutlined />
          Sair
        </span>
      </MenuAntd.Item>
    </MenuAntd>
  );

  return (
    <Container>
      <header className="main">
        <div className="contain">
          <div className="greenBar">
            {/* <img className="imgTopHeader" src={ImgTopHeader} /> */}
            {/* @ts-expect-error Server Component */}

            <Link
              to="/"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <div className="appName">ROTAS</div>
              <div className="separator" />
              <img className="logoImg" alt="SSPDSclara2" />
              <div className="sspdsName">
                <b className="longName">
                  SECRETARIA DA SEGURANÇA PÚBLICA E DEFESA SOCIAL
                </b>
                <p className="governName">GOVERNO DO ESTADO DO CEARÁ</p>
              </div>
            </Link>

            <div className="profile">
              <aside>
                <Profile>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span id="userName">{nome}</span>
                  </div>

                  <Dropdown overlay={menu}>
                    <Avatar
                      style={{
                        backgroundColor: '#f56a00',
                        border: '2px solid #fff',
                        verticalAlign: 'middle',
                      }}
                      src={image}
                      size="large"
                      className="avatar"
                    >
                      {user}
                    </Avatar>
                  </Dropdown>
                </Profile>
              </aside>
            </div>
          </div>

          <div className="header_content_menu">
            {/* <div className="baseOndas">
              <img src={BaseOndas} alt="" />
                    </div> */}
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                className="labelEco"
                style={{
                  display: 'flex',

                  justifyContent: 'center',

                  justifyItems: 'center',
                }}
              >
                <h2 style={{ color: '#00853b', fontWeight: 'bold' }}>
                  R.O.T.A.S.
                </h2>

                <>
                  {ENV.ambiente === 'dev' && (
                    <h3
                      style={{
                        color: 'red',

                        fontWeight: 'bold',

                        display: 'flex',

                        alignItems: 'center',

                        marginLeft: 4,
                      }}
                    >
                      - DESEN
                    </h3>
                  )}

                  {ENV.ambiente === 'stage' && (
                    <h3
                      style={{
                        color: 'orange',

                        fontWeight: 'bold',

                        display: 'flex',

                        alignItems: 'center',

                        marginLeft: 4,
                      }}
                    >
                      - HOMO
                    </h3>
                  )}

                  {ENV.ambiente === 'localhost' && (
                    <h3
                      style={{
                        color: 'orange',

                        fontWeight: 'bold',

                        display: 'flex',

                        alignItems: 'center',

                        marginLeft: 4,
                      }}
                    >
                      - LOCAL
                    </h3>
                  )}
                </>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="logosEco">
                <Link to="/" className="governo">
                  <div className="mark">
                    <h2 style={{ color: '#00853b', fontWeight: 'bold' }}>
                      R.O.T.A.S.
                    </h2>
                  </div>
                  <HomeOutlined
                    style={{
                      color: '#00853b',
                    }}
                  />
                </Link>
              </div>
            </div> */}
          </div>

          <div className="imgBottomHeader">
            <img src={ImgBottomHeader} />
          </div>
        </div>
      </header>
    </Container>
  );
}
