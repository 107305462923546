import React, { useCallback, useState } from 'react';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  Row,
  Typography,
  Divider,
  Progress,
  Col,
  Alert,
} from 'antd';

import { DownloadOutlined } from '@ant-design/icons';
import { Printer } from 'phosphor-react';
import { useLocation } from 'react-router-dom';
import api from 'services/api';
import { pdfReport } from './pdfReport';
import './styles.css';

import { ButtonSection } from './styles';
import { dataPropertiesAux } from 'interfaces/vehicle';
import { useHistoryData } from 'hooks/history/useHistoryData';
import { useHistoryDataType } from 'hooks/history/useHistoryDataType';
import { LoadFormattedAddress } from 'utils/functionsUtils';
import { ServiceExterno } from 'services/modulos/serviceExterno';
import { useAuthData } from 'hooks/auth/useAuthData';

const { Title } = Typography;

export interface CsvProps {
  placa: string;
  prefixo: string;
  equipamento: string;
  velocidade: string;
  dataPosicao: string;
  logradouro: string;
  latitude: string;
  longitude: string;
}

let csvData: CsvProps[] = [];

const datatypeOptions: any = {
  auditoria: {
    filename: 'Auditoria - ROTAS',
  },
  historicoIndividual: {
    filename: 'Relatório Individual - ROTAS',
  },
  raio: {
    filename: 'Pesquisa por Raio - ROTAS',
  },
};

const csvHeader = [
  { label: 'Placa', key: 'placa' },
  { label: 'Prefixo', key: 'prefixo' },
  { label: 'Equipamento', key: 'equipamento' },
  { label: 'Velocidade', key: 'velocidade' },
  { label: 'Data Posição', key: 'dataPosicao' },
  { label: 'Logradouro', key: 'logradouro' },
  { label: 'Latitude', key: 'latitude' },
  { label: 'Longitude', key: 'longitude' },
];

export function GenerateHistoryReport(map?: any) {
  const [loadingLogradouro, setLoadingLogradouro] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [percent, setPercent] = useState(0);

  const { data: dataAuth } = useAuthData();
  const { client, roles } = dataAuth;

  const location = useLocation();

  const isMonitoramento = location.pathname === '/mapa/monitoramento';

  const { dataType } = useHistoryDataType();
  const { dataHistorico } = useHistoryData();

  let mapImg: HTMLElement | null = null;

  if (map) {
    mapImg = document.getElementById('map'); // map-static-history-container
  }

  const { filename } = datatypeOptions[dataType.current || ''] || {};

  const loadAdderesses = useCallback(async () => {
    try {
      setLoadingLogradouro(true);
      if (csvData.length) {
        // const range = csvData.length;
        let per = 0;
        let counter = 0;

        let uniqueCoords: any = [];

        csvData.map(coord => {
          const concCoord =
            String(coord.latitude) + '|' + String(coord.longitude);

          uniqueCoords[concCoord] = {};
        });

        const uniqueCoordsArr: string[] = Object.keys(uniqueCoords);

        const range = uniqueCoordsArr.length;

        const times = range / 1000 + 1;

        for (let i = 0; i < times; i += 1) {
          const slicedArray = uniqueCoordsArr.slice(
            i * 1000,
            Math.min((i + 1) * 1000, range)
          );

          await Promise.all(
            slicedArray.map(async x => {
              let currentLogradouro = '';

              let [lat, lng]: string[] | number[] = x.split('|');

              lat = Number(lat);
              lng = Number(lng);

              currentLogradouro = await LoadFormattedAddress(lat, lng);

              uniqueCoords[x].logradouro = currentLogradouro;

              counter += 1;
              per = (counter * 100) / range;
              setPercent(parseInt(per + '', 10));
              if (counter >= range) {
                setIsLoadingData(false);
              }
              return x;
            })
          );
        }

        csvData.map(d => {
          const concCoord = String(d.latitude) + '|' + String(d.longitude);

          d.logradouro = uniqueCoords[concCoord].logradouro
            ? uniqueCoords[concCoord].logradouro
            : '*';

          return d;
        });
      }
    } catch (err) {
      console.log(err);
      toast.error('Erro ao preparar relatório.');
    } finally {
      setLoadingLogradouro(false);
    }
  }, []);

  const loadReportData = () => {
    try {
      setOpenModal(true);
      setIsLoadingData(true);

      if (dataHistorico.length > 0) {
        const dataFinal: dataPropertiesAux[] & { logradouro?: string } = [];

        // Registrando usuário que está gerando relatório
        ServiceExterno.generateReport({
          name: client.name,
          cpf: client.cpf,
          externPath: 'Google Geocoding API',
          pathName: location.pathname,
        });

        dataHistorico.map(d => d.positions.map(p => dataFinal.push(p)));

        csvData = dataFinal.map(
          (d: dataPropertiesAux & { logradouro?: string }) => {
            return {
              placa: d.placa,
              prefixo: d.prefixo,
              equipamento: d.idDevice,
              velocidade: `${d.speed} Km/h`,
              dataPosicao: `${new Date(
                d.datePosition
              ).toLocaleDateString()} ${new Date(
                d.datePosition
              ).toLocaleTimeString()}`,
              logradouro: d.logradouro || '',
              latitude: String(d.position?.x) || '',
              longitude: String(d.position?.y) || '',
            };
          }
        );

        loadAdderesses();
      }
    } catch (_) {
      setIsLoadingData(false);
      // toast.error(err.message || 'Não foi possivel carregar os dados.');
    }
  };

  return (
    <>
      <div>
        <Modal
          open={openModal}
          destroyOnClose
          onCancel={() => {
            setOpenModal(false);
          }}
          footer={null}
        >
          <Row>
            <Printer size={32} weight="light" />
            <Title
              level={4}
              style={{
                verticalAlign: 'middle',
              }}
            >
              {' '}
              Gerar Relatório
            </Title>
          </Row>
          <Divider
            style={{
              marginTop: '2px',
              marginBottom: '8px',
            }}
          />
          <Row
            style={{
              marginBottom: '1.5rem',
            }}
          >
            {csvData.length > 1000 ? (
              <Alert
                message="Atenção"
                description={`${csvData.length} registros. O carregamento do relatório em PDF pode demorar.`}
                type="warning"
                showIcon
                closable
              />
            ) : (
              <div />
            )}
          </Row>
          {isLoadingData ? (
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Progress
                  type="circle"
                  strokeColor="#006B35"
                  percent={percent}
                />
              </Row>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {' '}
                Carregando dados...
              </Row>
            </div>
          ) : (
            <Row>
              <Col
                span={12}
                style={{
                  textAlign: 'center',
                  margin: '0 auto',
                }}
              >
                <Button
                  onClick={() => {
                    if (csvData) {
                      pdfReport(csvData, mapImg);
                    } else {
                      toast.error('Dados insuficientes para exportação.');
                    }
                  }}
                >
                  Gerar PDF
                </Button>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: 'center',
                  margin: '0 auto',
                }}
              >
                <Button>
                  {/* @ts-ignore */}
                  <CSVLink
                    headers={csvHeader}
                    data={csvData}
                    filename={filename}
                    target="_blank"
                  >
                    Gerar CSV
                  </CSVLink>
                </Button>
              </Col>
            </Row>
          )}
        </Modal>
      </div>
      {!isMonitoramento && roles?.includes('ROLE_ROTAS_RELATORIOS') && (
        <ButtonSection>
          <Button
            loading={loadingLogradouro}
            className="btnSalvarConsulta"
            onClick={loadReportData}
            disabled={!Boolean(dataHistorico.length > 0)}
            icon={<DownloadOutlined />}
          >
            <span id="text">
              {' '}
              {loadingLogradouro ? 'Aguarde...' : 'Gerar Relatório'}
            </span>
          </Button>
        </ButtonSection>
      )}
    </>
  );
}
